import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

/*
// Large cards (2) image widths at all breakpoints:
// layout:     | ----------------- 1 col ----------------- | -------------------------- 2 col --------------------------- |
// Container:  | --- xxs --- | ---- xs ---- | ---- sm ---- | ---- md ---- | ---- lg ----- | ---- xl ----- | ---- xxl ----->
// Breakpoint: 0            375            600            768            992             1200            1920             ~
// Img width:  |  290-344px  |   345-370px  |     546px    |    330px     |     450px     |     540px     |     540px     |
*/

/**
 * @typedef {Object} CardData
 * @property {number} cardNumber - Required number of the card.
 * @property {GatsbyImageData} imgData - Required Gatsby image data.
 * @property {string} altText - Required string image alt text.
 * @property {string} heading - Green title text of the card.
 * @property {string} text - h4 text below the title of the card.
 * @property {string} linkUrl - Href string where to go. Ex: "/es/banca-personal".
 * @property {string} btnText - Text displayed on the button that contains the link.
 */

/**
 * This is the component to render the spanish large cards in a row, it can handle just 2 cards for now.
 *
 * NOTE: please do NOT change the margin-bottom classes on these cards, it should be "mb-3 mb-lg-4".
 * Do NOT override the spacing using padding or margin classes on the "containerClass".
 * 
 * @param {Object} props - The object containing props.
 * @param {string} props.containerClass - div container classes like "bg-info".
 * @param {CardData[]} props.cardData - Required Array of objects with the data and content for the card.
 *   - `cardNumber`: required number of the card.
 *   - `imgData`: required Gatsby image data.
 *   - `altText`: required string image alt text.
 *   - `heading`: green title text of the card.
 *   - `text`: h4 text below the title of the card.
 *   - `linkUrl`: link string where to go like "/es/banca-personal".
 *   - `btnText`: button text to go to the link.
 * @param {bool} props.noBackground - If false or undefined we use "bg-info pt-0 pb-3" for the section classes.
 * @param {string} props.sectionClass - If noBackground is true we use it like this "container ${sectionClass}" for the section classes.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const LargeCards = ({ containerClass = "bg-info", cardData: propCardData = null }) => {
  const imgData = useStaticQuery(graphql`
    {
      largeCard1ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-greenlight-082224.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-credit-rewards-082224.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const defaultCardData = [
    {
      // Default (Card 1)
      cardNumber: 1,
      imgData: imgData.largeCard1ImageVar.childImageSharp.gatsbyImageData,
      altText: "Mother with phone and debit card teaching child about finances.",
      heading: "This should be translated to Spanish",
      text: "Esto debe de estar en Español.",
      linkUrl: "/personal-banking/greenlight-kids-debit-card",
      btnText: "Get Greenlight"
    },
    {
      // Default (Card 2)
      cardNumber: 2,
      imgData: imgData.largeCard2ImageVar.childImageSharp.gatsbyImageData,
      altText: "Happy couple in restaurant with laptop and credit card.",
      heading: "This should be translated to Spanish",
      text: "Esto debe de estar en Español.",
      linkUrl: "/personal-banking/credit-cards",
      btnText: "Check it Out"
    }
  ];

  // Use Props cardData if found, else use the default (used here due to imageVariable being used on the cards).
  const cardData = propCardData || defaultCardData;
  const btnText = "Conoce más";

  return (
    <div className={`container ${containerClass}`}>
      <div id="homepage-large-cards" className="row large-cards">
        {cardData.map((card, cardIndex) => {
          return (
            <div className="col-md mb-3 mb-lg-4" key={cardIndex}>
              <div
                id={`homepage-large-card-${card.cardNumber}`}
                className="card h-100 position-relative border-radius-12 border-0"
              >
                <GatsbyImage
                  className="border-radius-12 border-radius-bottom-0"
                  image={card.imgData}
                  alt={card.altText}
                />
                <div className="card-body pb-0">
                  <h2
                    className="text-success font-weight-semi-bold"
                    dangerouslySetInnerHTML={{ __html: card.heading }}
                  />
                  <h4 dangerouslySetInnerHTML={{ __html: card.text }} />
                </div>
                <div className="card-footer bg-white border-0 border-radius-bottom-12">
                  <div className="row">
                    <div className="col-sm-8 col-md-12 col-lg-8">
                      <Link
                        id={`homepage-large-card-${card.cardNumber}-btn`}
                        className="btn btn-primary w-100 stretched-link"
                        to={card.linkUrl}
                      >
                        {card.btnText || btnText}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LargeCards;

LargeCards.defaultProps = {
  containerClass: PropTypes.string,
  cardData: PropTypes.arrayOf(
    PropTypes.shape({
      cardNumber: PropTypes.number.isRequired,
      imgData: PropTypes.object.isRequired,
      altText: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      linkUrl: PropTypes.string.isRequired,
      btnText: PropTypes.string.isRequired
    })
  )
};
